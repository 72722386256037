<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height justify-content-md-center">
      <b-col
        xl="6"
        md="6"
        center
        >
        <b-card>
          <template #header>
            <b-card-title>
              <h5 class="mb-0" bold>{{data.subject}}<span><b-badge variant="secondary" class="mr-1"> {{data.status}}</b-badge></span></h5>
            </b-card-title>
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              size="sm"
              @click="doClose"
              >
              {{btnName}}
            </b-button>
          </template>
          <b-card-body>
            <div v-for="item in data.messages" v-bind:key="item._id">
              <b-card>
                <template #header>
                  <b-media vertical-align="center">
                      <template #aside>
                        <b-avatar
                          size="40"
                          :src="item.email == 'system' ? avatar : avatar_user"
                          :variant="`light-success`"
                        />
                      </template>
                      <h6 v-if="item.email == 'system'" class="text-success">System</h6>
                      <h6 v-else class="text-success">You</h6>
                      <small class="text-muted">(Customer Support)</small>
                  </b-media>
                  <p style="font-size: 0.95rem; color: #b2bece;">{{getTimeInterval(new Date(item.created_at))}}</p>
                </template>
                <b-card-body>
                  {{item.message}}
                </b-card-body>
              </b-card>
            </div>
          </b-card-body>
          <b-card-footer v-if="!data.close">
            <b-form-textarea
              id="Message"
              placeholder="Enter Message"
              rows="3"
              v-model="message"
            />
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="secondary"
              size="sm"
              class="mt-1 text-left"
              @click="sendMessage"
              >
              <feather-icon 
              icon="SendIcon"/>
              Send
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BMedia, BLink, BCard, BFormTextarea, BForm, BRow, BCol, BCardHeader, BCardTitle, BCardText, BCardFooter
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import RepositoryFactory from '../../../api/RepositoryFactory'
const Supports = RepositoryFactory.get('suports')

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BMedia,
    BLink,
    BCard,
    BFormTextarea,
    BForm,
    BRow, BCol, BCardHeader, BCardTitle, BCardText, BCardFooter
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatar: require('@/assets/images/avatars/user_headset.svg'),
      avatar_user: require('@/assets/images/avatars/user.png'),
      email: this.$store.getters['auth/userInfo'].email,
      data:{},
      message: "",
      btnName: "Close"

    }
  },
  created(){
    Supports.getTicketContent(this.$router.currentRoute.params.id).then(rs=>{
      this.data = rs.data.data
      this.btnName = this.data.close ? "Re-open" : "Close"
    })
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
  },
  methods: {
    doClose(){
      Supports.closeTicket(this.$router.currentRoute.params.id).then(rs =>{
        this.data = rs.data.data
        this.btnName = this.data.close ? "Re-open" : "Close"
      })
  
    },
    sendMessage(){
      if(this.message == "")
        return
      Supports.addMessage(this.$router.currentRoute.params.id,{message: this.message}).then(rs=>{
        this.data.messages.push({
          email: this.email,
          message: this.message
        })
        this.message = ""
      })
      
    },
    getTimeInterval(date) {
      let seconds = Math.floor((Date.now() - date) / 1000);
      let unit = "second";
      let direction = "ago";
      if (seconds < 0) {
        seconds = -seconds;
        direction = "from now";
      }
      let value = seconds;
      if (seconds >= 31536000) {
        value = Math.floor(seconds / 31536000);
        unit = "year";
      } else if (seconds >= 86400) {
        value = Math.floor(seconds / 86400);
        unit = "day";
      } else if (seconds >= 3600) {
        value = Math.floor(seconds / 3600);
        unit = "hour";
      } else if (seconds >= 60) {
        value = Math.floor(seconds / 60);
        unit = "minute";
      }
      if (value != 1)
        unit = unit + "s";
      return value + " " + unit + " " + direction;
    }
  },
}
</script>
